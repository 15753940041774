import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-cnd",
    templateUrl: "./service-cnd.component.html",
    styleUrls: ["./service-cnd.component.scss"],
})
export class ServiceCndComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
