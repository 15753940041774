<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Chaudronnerie</h2>
                <p>
                    Le pôle chaudronnerie d’ALPI SOLUTIONS SARL  possède son propre bureau d’études afin de pouvoir proposer une offre complète à ses clients.
                </p>

                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Proposition de solutions adaptées aux besoins de nos clients en adéquation avec le savoir-faire et les capacités de production de la société</h4>
                    <h4><i class="flaticon-tick"></i> Définition du cahier des charges</h4>
                </div>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/chaudronnerie.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
