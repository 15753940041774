<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <!-- 2e description -->
                <h2>
                    Les ensembles mécano-soudés sont réalisés dans les ateliers
                    d’ALPI ou sur chantier
                </h2>
                <h4>
                    L’internalisation des différents postes de soudures et
                    d’assemblage permet à ALPI de maîtriser l’ensemble de la
                    chaîne de production.
                </h4>

                <p>
                    Le soudage permet d’assembler une grande variété de tôles.
                    Selon la destination de la pièce et la qualité de finition
                    souhaitée, ALPI conseille ses clients sur le procédé à
                    utiliser. Le <b>soudage TIG</b> (Tungsten Inert Gas), est
                    plus fin et offre des qualités esthétiques. Les
                    <b>soudures MIG</b> (Métal Inert Gas) et MAG (Métal Active
                    Gas), sont plus rapides et offrent une plus grande tenue
                    mécanique. Les <b>soudures TIG</b> et <b>MIG</b> sont
                    utilisées pour l’inox et l’aluminium. Les soudures TIG et
                    MAG privilégiées pour l’acier. Les soudeurs d’ALPI sont
                    qualifiés sous licences, ce qui leur permet de souder sur
                    acier, inox et alu.
                </p>
                
                <!-- 1e description -->
                <h2 style="margin-top: 5rem;">Une organisation spécifique</h2>
                <h4>
                    1 atelier dédié aux différents modes de production.
                </h4>
                <p>
                    1 atelier dédié à la soudure, avec les équipements permettent une performance très élevé.
                    Les structures sur-mesure, mécano-soudées en tôle, barre et tube, assemblées par nos
                    métalliers-soudeurs peuvent intégrer des pièces usinées. Le montage se fait à la demande, les
                    finitions sont soignées.
                    Soudure de goujons ou d’écrous, assemblage vissé ou riveté, les prestations d’ALPI sont
                    personnalisées selon vos attentes.
                    L’entreprise est attentive aux équipements, à la sécurité et aux conditions de travail de ses
                    collaborateurs à toutes les étapes de la fabrication.
                </p>
            
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/soudure.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
