<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Fourniture des matériaux diverses</h2>
                <p>
                    Grâces à nos partenaires et nos expérience du monde industriel, nous pouvons fournir nos clients en :
                </p>

                <div class="features-text">
                    <h4>
                        <i class="flaticon-tick"></i> Matériels de sécurités collectives (ldv filet…)
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Matériels de sécurité individuel (harnais, sangle, stop chut…)
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Matériels de chantier (meule, chignole …)
                    </h4>
                </div>
                <p>
                    Plus encore avec l’aide de nos différents partenaires internationaux.
                </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/delivery.png" alt="image" />
            </div>
        </div>
    </div>
</section>
