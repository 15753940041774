<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Montage des structures et montages des rayonnages</h2>
                <p>
                    Le fait de travailler directement avec les fabricants, et la profonde connaissance de la composition
                    et du comportement des matériaux, nous accorde une grande fiabilité aussi bien dans le respect des
                    délais de livraison que dans le montage des nouvelles installations.
                    ALPI SOLUTIONS SARL s’occupe d’évaluer et de vérifier les exigences et les règlementations propres
                    de chaque pays et chaque région pour procéder à l’exécution des travaux avec toutes les garanties.
                    Ses équipes sont homologuées non seulement pour le montage de structures métalliques complexes, mais
                    aussi pour l’édification et le démontage d’autres éléments complémentaires tels que rayonnages,
                    cloisons, etc.
                    Toutes les équipes de montage répondent aux exigences requises par la Loi de Prévention des Risques
                    Professionnels afin de garantir le montage correct des installations en l’absence d’accidents.
                </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/structure.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
