<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Intervention en espace confiné</h2>
                <p>
                    La compréhension et la maîtrise des risques encourus permettent d’assurer la sécurité des
                    interventions en espace confiné,
                    dans les cuves et réservoirs. L’évaluation méticuleuse des risques s'avère être le moyen le plus
                    efficace pour éviter les accidents voire les décès.
                </p>
                <p>Nos interventions en espace confiné sont diverses :</p>

                <div class="features-text">
                    <h4>
                        <i class="flaticon-tick"></i> purges des silos trémies
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> décolmatages
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> inspections
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> nettoyages  par aspiration ou part brossage etc.
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> soudures
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Etc…
                    </h4>
                </div>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/espace-confine.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
