import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-chaudronnerie",
    templateUrl: "./service-chaudronnerie.component.html",
    styleUrls: ["./service-chaudronnerie.component.scss"],
})
export class ServiceChaudronnerieComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
