import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-ligne-vie",
    templateUrl: "./service-ligne-vie.component.html",
    styleUrls: ["./service-ligne-vie.component.scss"],
})
export class ServiceLigneVieComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
