import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PartnerComponent } from './components/pages/partner/partner.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { HomeDemoFourComponent } from './components/pages/home-demo-four/home-demo-four.component';
import { HomeDemoFiveComponent } from './components/pages/home-demo-five/home-demo-five.component';
import { HomeDemoSixComponent } from './components/pages/home-demo-six/home-demo-six.component';
import { HomeDemoSevenComponent } from './components/pages/home-demo-seven/home-demo-seven.component';
import { HomeDemoEightComponent } from './components/pages/home-demo-eight/home-demo-eight.component';
import { HomeDemoNineComponent } from './components/pages/home-demo-nine/home-demo-nine.component';
import { AboutStyleTwoComponent } from './components/pages/about-style-two/about-style-two.component';
import { TeamStyleTwoComponent } from './components/pages/team-style-two/team-style-two.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { ProjectsStyleTwoComponent } from './components/pages/projects-style-two/projects-style-two.component';
import { ProjectsStyleThreeComponent } from './components/pages/projects-style-three/projects-style-three.component';
import { ProjectsStyleFourComponent } from './components/pages/projects-style-four/projects-style-four.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { HomeDemoTenComponent } from './components/pages/home-demo-ten/home-demo-ten.component';
import { HomeDemoElevenComponent } from './components/pages/home-demo-eleven/home-demo-eleven.component';
import { HomeDemoTwelveComponent } from './components/pages/home-demo-twelve/home-demo-twelve.component';

const routes: Routes = [
    { path: '', component: HomeDemoFiveComponent },
    { path: 'about', title: 'about', component: AboutComponent },
    { path: 'services', title: 'services', component: ServicesComponent },
    { path: 'service-details/:id', title: 'service-details', component: ServiceDetailsComponent },
    { path: 'gallerie', title: 'gallerie',component: ProjectsComponent },
    { path: 'realisations', title: 'realisations', component: BlogComponent },
    { path: 'contact', title: 'contact', component: ContactComponent },
    // {path: 'index-2', component: HomeDemoTwoComponent},
    // {path: 'index-3', component: HomeDemoThreeComponent},
    // {path: 'index-4', component: HomeDemoFourComponent},
    // {path: 'index-5', component: HomeDemoFiveComponent},
    // {path: 'index-6', component: HomeDemoSixComponent},
    // {path: 'index-7', component: HomeDemoSevenComponent},
    // {path: 'index-8', component: HomeDemoEightComponent},
    // {path: 'index-9', component: HomeDemoNineComponent},
    // {path: 'index-10', component: HomeDemoTenComponent},
    // {path: 'index-11', component: HomeDemoElevenComponent},
    // {path: 'index-12', component: HomeDemoTwelveComponent},
    // {path: 'about-2', component: AboutStyleTwoComponent},
    // {path: 'services-2', component: ServicesStyleTwoComponent},
    // {path: 'services-3', component: ServicesStyleThreeComponent},
    // {path: 'projects-2', component: ProjectsStyleTwoComponent},
    // {path: 'projects-3', component: ProjectsStyleThreeComponent},
    // {path: 'projects-4', component: ProjectsStyleFourComponent},
    // {path: 'project-details', component: ProjectDetailsComponent},
    // {path: 'blog-2', component: BlogRightSidebarComponent},
    // {path: 'blog-details', component: BlogDetailsComponent},
    // {path: 'features', component: FeaturesComponent},
    // {path: 'team', component: TeamComponent},
    // {path: 'team-2', component: TeamStyleTwoComponent},
    // {path: 'pricing', component: PricingComponent},
    // {path: 'partner', component: PartnerComponent},
    // {path: 'faq', component: FaqComponent},
    // {path: 'login', component: LoginComponent},
    // {path: 'register', component: RegisterComponent},
    // Here add new pages component

    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }