<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Why Choose Us Area -->
<!-- <section class="why-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <span class="sub-title">Why Choose Us</span>
                    <h2>The Key To Your Motivation And Success</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Core Development</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Define Your Choices</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/why-choose-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Why Choose Us Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Ce que nous faisons</span>
            <h2>Les Services Que Nous Fournissons ...</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.MAINTENANCE_INDUSTRIELLE]">Maintenance Industrielle</a></h3>
                    <p>Notre mission est d’assurer la continuité de fonctionnement de vos équipements...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.MAINTENANCE_INDUSTRIELLE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <i class="flaticon-research"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.CHARPENTE]">Charpente métallique fabrication et montage</a></h3>
                    <p>ALPI SOLUTIONS SARL  intervient dans la réalisation et le montage de structures métalliques...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.CHARPENTE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.LIGNE_VIE]">Installation des lignes de vie et point d'encrage</a></h3>
                    <p>ALPI SOLUTIONS SARL , spécialisée dans le travail en hauteur, réalise la pose de lignes de vie...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.LIGNE_VIE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.PYLONE]">Pylôniste</a></h3>
                    <p>Afin d’assurer la pérennité de vos pylônes et pour optimiser la qualité de transmission...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.PYLONE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.SURFACE]">Traitement de surface</a></h3>
                    <p>Lessivage pour industrielle et particulier...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.SURFACE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-seo"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.CHAUDRONNERIE]">Chaudronnerie</a></h3>
                    <p>Le pôle chaudronnerie d’ALPI SOLUTIONS SARL  possède son propre bureau d’études afin de pouvoir...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.CHAUDRONNERIE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.PLOMBERIE]">Plomberie</a></h3>
                    <p>ALPI SOLUTIONS SARL  accorde un intérêt particulier à l’optimisation de la qualité́ de vie...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.PLOMBERIE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.SOUDURE]">Soudure</a></h3>
                    <p>Les ensembles mécano-soudés sont réalisés dans les ateliers d’ALPI ou sur chantier</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.SOUDURE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.ESPACE_CONFINE]">Espaces confinés</a></h3>
                    <p>La compréhension et la maîtrise des risques encourus permettent d’assurer la sécurité des interventions...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.ESPACE_CONFINE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.ENTANCHEITE]">Etanchéité</a></h3>
                    <p>La valorisation de votre patrimoine et sa pérennité dans le temps est fondamentale à vos yeux...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.ENTANCHEITE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.CND]">CND</a></h3>
                    <p>Avec plus de 8 ans d’expérience dans le contrôle non destructif (CND) le personnel ALPI SOLUTIONS SARL  est formé en diffèrent technique...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.CND]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.PUBLICITE]">Affichage publicitaire</a></h3>
                    <p>Nous vendons l’accès pour les affiches les plus grandes possible sur les bâtiments...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.PUBLICITE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.FOURNITURE_MATERIAUX]">Fourniture des matériaux diverses</a></h3>
                    <p>Grâces à nos partenaires et nos expérience du monde industriel, nous pouvons fournir nos clients en...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.FOURNITURE_MATERIAUX]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a [routerLink]="['/service-details', SERVICE_TYPE.MONTAGE_STRUCTURE]">Montage de structure de stockage (racks)</a></h3>
                    <p>Le fait de travailler directement avec les fabricants, et la profonde...</p>
                    <a [routerLink]="['/service-details', SERVICE_TYPE.MONTAGE_STRUCTURE]" class="read-more-btn">Lire plus <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->