import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-fourniture-materiaux",
    templateUrl: "./service-fourniture-materiaux.component.html",
    styleUrls: ["./service-fourniture-materiaux.component.scss"],
})
export class ServiceFournitureMateriauxComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
