import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-plomberie",
    templateUrl: "./service-plomberie.component.html",
    styleUrls: ["./service-plomberie.component.scss"],
})
export class ServicePlomberieComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
