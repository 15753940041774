<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Contrôle non destructif</h2>
                <p>
                    Avec plus de 8 ans d’expérience dans le contrôle non destructif (CND) le personnel ALPI SOLUTIONS
                    SARL est formé en diffèrent technique d’inspection l’ultrason, la magnétoscopie, le ressuage et
                    contrôle visuel. Il est disposé à faire d’autres formations dans le seul but de vous satisfaire.
                    Nous avons aidé de nombreux clients à résoudre leurs problèmes d’inspection. Parfois avec des accès
                    très difficile. Notre bibliothèque de ressources est le résultat de cette connaissance et de cette
                    expérience approfondie des CND.
                </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/cnd.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
