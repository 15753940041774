export enum SERVICE_TYPE {
    MAINTENANCE_INDUSTRIELLE = "MI",
    CHARPENTE = "CMFM",
    LIGNE_VIE = "LIGNE_VIE",
    PYLONE = "PYLONE",
    SURFACE = "SURFACE",
    CHAUDRONNERIE = "CHAUDRONNERIE",
    PLOMBERIE = "PLOMBERIE",
    SOUDURE = "SOUDURE",
    ESPACE_CONFINE = "ESPACE_CONFINE",
    ENTANCHEITE = "ENTANCHEITE",
    CND = "CND",
    PUBLICITE = "PUBLICITE",
    FOURNITURE_MATERIAUX = "FOURNITURE_MATERIAUX",
    MONTAGE_STRUCTURE = "MONTAGE_STRUCTURE",
}

export let SERVICE_TITLE = {
    [SERVICE_TYPE.MAINTENANCE_INDUSTRIELLE]: "Maintenance Industrielle",
    [SERVICE_TYPE.CHARPENTE]: "Charpente métallique fabrication et montage",
    [SERVICE_TYPE.LIGNE_VIE]: "Installation des lignes de vie et point d'encrage",
    [SERVICE_TYPE.PYLONE]: "Travaux sur pylônes",
    [SERVICE_TYPE.SURFACE]: "Traitement de surface",
    [SERVICE_TYPE.CHAUDRONNERIE]: "Chaudronnerie",
    [SERVICE_TYPE.PLOMBERIE]: "Plomberie",
    [SERVICE_TYPE.SOUDURE]: "SOUDURE ET ASSEMBLAGE",
    [SERVICE_TYPE.ESPACE_CONFINE]: "Intervention en espace confiné",
    [SERVICE_TYPE.ENTANCHEITE]: "Etanchéités",
    [SERVICE_TYPE.CND]: "Contrôle non destructif",
    [SERVICE_TYPE.PUBLICITE]: "Affichage publicitaire",
    [SERVICE_TYPE.FOURNITURE_MATERIAUX]: "Fourniture des matériaux diverses",
    [SERVICE_TYPE.MONTAGE_STRUCTURE]:
        "Montage des structures et montages des rayonnages",
};
