<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Informations de Contact</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Un service d'urgence 24h/24 et 7/7jours.</span>
                            <a href="tel:+237676477867">676 477 867 /</a>&nbsp;<a href="tel:+237696526228">696 526 228</a>&nbsp;
                            &nbsp;<a href="tel:+237691269716">/ 691 269 716</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Avez-Vous Des Questions?</span>
                            <a href="mailto:Infos@alpisolutuons.pro">infos@alpisolutuons.pro</a>
                        </li>
                        <li>
                            <i class="flaticon-social-media"></i>
                            <span>Réseaux sociaux</span>
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Liens Rapides</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Service</a></li>
                        <li><a routerLink="/">Nos réalisations</a></li>
                        <li><a routerLink="/">Gallerie</a></li>
                        <li><a routerLink="/">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Quelques photos</h3>
                    <ul class="footer-instagram-post">
                        <li *ngFor="let i of footerImages$ | async">
                            <a href="#" target="_blank">
                                <img [src]="'assets/img/footer/1 (' + i + ').jpg'" alt="image">
                            </a>
                        </li>
                        <!-- <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram2.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram3.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram4.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram5.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram6.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram7.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram8.jpg" alt="image">
                        </a></li>
                        <li><a href="#" target="_blank">
                            <img src="assets/img/instagram-image/instagram1.jpg" alt="image">
                        </a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright @{{currentYear}} <a href="https://envytheme.com/" target="_blank">FlashTech</a>. All rights reserved</p>
                </div>
                <!-- <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->