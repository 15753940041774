import { Component, OnInit } from '@angular/core';
import { SERVICE_TYPE } from 'src/app/services/constantes';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  SERVICE_TYPE = SERVICE_TYPE;

  constructor() { }

  ngOnInit() {
  }

}
