<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>IT Services</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>IT Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Overview Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Digital Marketing</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                SEO Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Email Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Facebook Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Data Scraping
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Social Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Youtube Marketing
                            </span>
                        </li>
                    </ul>

                    <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service1.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service2.png" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>Web Design & Development</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Responsive Design
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                UI / UX Design
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Mobile App Development
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Laravel Development
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                React Development
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Angular Development
                            </span>
                        </li>
                    </ul>

                    <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Cloud Storage Service</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Cloud Database
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Hybrid Cloud
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Email Servers
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Website Hosting
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                File Storage
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Backup Systems
                            </span>
                        </li>
                    </ul>

                    <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service3.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service4.png" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>SEO Consultancy</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Content Marketing</h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> SEO Optimization</h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Social Marketing</h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Services Area -->
<section class="pt-100 pb-70 gray-bg">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bx-conversation"></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">IT Consultancy</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bx-laptop"></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Web Development</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bxs-megaphone"></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Digital Marketing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class='bx bx-mobile-alt'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Mobile App Development</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class='bx bx-cart'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">eCommerce Development</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class='bx bx-list-check'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">IT Solutions</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->