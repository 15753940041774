import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  partners$: Observable<string[]> = of([
    "cimaf.jpeg",
    "sabc.jpeg",
    "sosucam.jpeg",
    "somdiaa.jpeg",
    "wso.jpeg"
  ])
  constructor() { }

  ngOnInit() {
  }

}
