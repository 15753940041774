import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  datas$: Observable<{title: string, img: string}[]> = of([
    {title: "ECHAFFAUDAGE OFFSHORE", img: "echaffaudage offshore.jpg"},
    {title: "LESSIVAGE EXTERNE MENEURIE SABC DOUALA", img: "lessivage meneurie sabc.jpg"},
    {title: "NETTOYAGE BARDAGE DANGOTE DOUALA", img: "nettoyage bardage dangote.jpg"},
    {title: "PURGE SILO DE STOCKAGE DE MALT SABC-DOUALA", img: "puge silo cimencam figuil.jpg"},
    {title: "MONTAGES DE BARDAGES ET STRUCTURES METALLIQUES", img: "montage bardage usine azur dibamba2.jpg"},
    {title: "MONTAGE ET REMPLACEMENT TOITURES", img: "remplacement de toiture socaver.jpg"},
    {title: "INSTALLATION CHENAUX ET CONDUITS D'EVACUATION", img: "montage chéneau.jpg"},
    {title: "PEINTURE ET TRAITEMENT DE SURFACE", img: "PEINTURE ET TRAITEMENT DE SURFACE.jpg"},
    {title: "MONTAGE DES BACHES PUBLICITAIRES", img: "montage bache publicitaire.jpg"},
    {title: "INSPECTION ET ESPACES CONFINES", img: "inspection CND.jpg"}
  ]);

  constructor() { }

  ngOnInit() {
  }

}
