import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-pylone",
    templateUrl: "./service-pylone.component.html",
    styleUrls: ["./service-pylone.component.scss"],
})
export class ServicePyloneComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
