import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-etancheite",
    templateUrl: "./service-etancheite.component.html",
    styleUrls: ["./service-etancheite.component.scss"],
})
export class ServiceEtancheiteComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
