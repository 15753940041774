<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li routerLink="/services">Services</li>
                        <li *ngIf="id">{{SERVICE_TITLE[id]}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<ng-container [ngSwitch]="id">
    <app-service-maintenance-industrielle *ngSwitchCase="SERVICE_TYPE.MAINTENANCE_INDUSTRIELLE"></app-service-maintenance-industrielle>
    <app-service-charpente *ngSwitchCase="SERVICE_TYPE.CHARPENTE"></app-service-charpente>
    <app-service-ligne-vie *ngSwitchCase="SERVICE_TYPE.LIGNE_VIE"></app-service-ligne-vie>
    <app-service-pylone *ngSwitchCase="SERVICE_TYPE.PYLONE"></app-service-pylone>
    <app-service-surface *ngSwitchCase="SERVICE_TYPE.SURFACE"></app-service-surface>
    <app-service-chaudronnerie *ngSwitchCase="SERVICE_TYPE.CHAUDRONNERIE"></app-service-chaudronnerie>
    <app-service-plomberie *ngSwitchCase="SERVICE_TYPE.PLOMBERIE"></app-service-plomberie>
    <app-service-soudure *ngSwitchCase="SERVICE_TYPE.SOUDURE"></app-service-soudure>
    <app-service-espace-confine *ngSwitchCase="SERVICE_TYPE.ESPACE_CONFINE"></app-service-espace-confine>
    <app-service-etancheite *ngSwitchCase="SERVICE_TYPE.ENTANCHEITE"></app-service-etancheite>
    <app-service-cnd *ngSwitchCase="SERVICE_TYPE.CND"></app-service-cnd>
    <app-service-publicite *ngSwitchCase="SERVICE_TYPE.PUBLICITE"></app-service-publicite>
    <app-service-fourniture-materiaux *ngSwitchCase="SERVICE_TYPE.FOURNITURE_MATERIAUX"></app-service-fourniture-materiaux>
    <app-service-structure *ngSwitchCase="SERVICE_TYPE.MONTAGE_STRUCTURE"></app-service-structure>
</ng-container>
<!-- End Services Details Area -->