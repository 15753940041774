<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Lessivage pour industrielle et particulier (Gardien de votre propreté)</h2>
                <p>
                    S’il est impératif de lessiver les murs et plafonds à l’occasion de leur remise en peinture ou d’une rénovation, le lessivage des murs et plafonds fait partie des opérations d’entretien régulières de l’intérieur de vos locaux.
En confiant le lessivage de vos murs et plafonds à ALPI, vous bénéficiez d’une prestation professionnelle pour l’ensemble de vos espaces et de vos surfaces :
                </p>

                <div class="features-text">
                    <h4>
                        <i class="flaticon-tick"></i> couloirs
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> cuisine
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> bureaux
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> cage d’escalier
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> salle de réunion
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> salles de cours...
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> murs extérieur.
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Plateformes
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Bardages
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Toitures
                    </h4>
                </div>
                <p>
                    Équipés de matériels et produits adaptés pour le nettoyage en profondeur de vos espaces, les équipes ALPI assurent le lessivage des peintures, carrelages et des murs encrassés, les débarrassant des tâches, traces de gras, des moisissures ou de nicotine et autres marques du temps.
Murs, portes, plinthes, plafonds hauts etc…, même difficiles d’accès retrouvent, après lessivage, un état de propreté qu’on avait pu oublier.
                </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/traitement-surface.jpg" alt="image" />
            </div>
        </div>
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Peintures</h2>
                <p>
                    Dans la grande chaîne des métiers du bâtiment, le peintre en bâtiment apporte la touche finale. Une qualité irréprochable s'obtient d'abord au prix d'un long travail de préparation des murs et des plafonds. Il faut boucher, enduire, poncer, pour obtenir des surfaces parfaitement lisses. Ensuite, l'œil et le savoir-faire du peintre sont déterminants pour créer la magie d'une atmosphère. Il prépare les mélanges de couleurs et harmonise les nuances. Les techniques et les matériaux sont en évolution constante : de la simple application de peinture à la pose de tissus, en passant par le papier peint, les revêtements vinyliques, les moquettes collées, les plaques de liège. Les nouvelles peintures décoratives permettent une meilleure présentation final du travail ou encore d'obtenir des effets patinés, froissés ou épongés notamment. Les chantiers changent souvent, les clients aussi : le peintre en bâtiment doit avoir le sens du contact développé. L'avenir dans ce marché consiste à proposer une offre globale au client. Le peintre doit savoir se faire plâtrier, s'intéresser à la moquette, au carrelage, aux meubles ou même à la façade.
                                    Chez nous avec des techniciens bien formés et professionnel expérimentés vous êtes assuré d’un excellent résultat
                </p>
                <p>Nous saurons vous conseiller dans votre choix de décoration </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/peinture.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
