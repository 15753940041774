<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Etanchéités</h2>
                <p>
                    La valorisation de votre patrimoine et sa pérennité dans le temps est fondamentale à vos yeux.
                    Le bon état de l'étanchéité de votre immeuble est donc un sujet majeur. Dans le bâtiment,
                    l’étanchéité désigne le fait d’empêcher tout élément extérieur comme l'eau et l'air d’entrer par les
                    murs ou le toit et d’endommager la construction. Le maître d’ouvrage doit réaliser une bonne
                    étanchéité à l’eau.
                    Être étanche signifie empêcher l’entrée d’eau et d’air parasitaire. Une bonne étanchéité à l’air est
                    donc indispensable afin d’obtenir une bonne performance énergétique du bâtiment pour minimiser vos
                    consommations énergétiques et aussi protéger vos équipements. L’existence de trous dans l’enveloppe
                    d’un bâtiment peut en effet entraîner des fuites d'eau mais aussi des déperditions de calories.
                    Les déperditions thermiques proviennent à 40% de la façade et à 15% de la toiture, qu’il s’agisse
                    d’une toiture classique ou d’une toiture terrasse. Ces chiffres sont des moyennes constatées sur les
                    immeubles construits selon les normes EU.
                </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/etancheite.png" alt="image" />
            </div>
        </div>
    </div>
</section>
