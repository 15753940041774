import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-structure",
    templateUrl: "./service-structure.component.html",
    styleUrls: ["./service-structure.component.scss"],
})
export class ServiceStructureComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
