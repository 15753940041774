<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Affichage publicitaire</h2>
                <p>
                    Nous vendons l’accès pour les affiches les plus grandes possible sur les bâtiments et des
                    équipements industriel, des panneaux préfabriqués par nous ou d’autre. Avec une équipe de
                    chaudronnier soudé la fabrication des panneaux est une tâche simple.
                    Nous nous intéressons aux besoins spécifiques de nos clients afin de trouver la solution d’affichage
                    qui leur conviendra parfaitement.
                </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/affiche.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
