<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Charpente métallique fabrication et montage</h2>
                <p>
                    ALPI SOLUTIONS SARL intervient dans la réalisation et le
                    montage de structures métalliques des plus simples aux plus
                    complexes dans la sous-région Afrique centrale. Nous mettons
                    notre expertise et le sens du détail qui nous caractérisent
                    au service de vos projets de bâtiments industriels, afin de
                    vous proposer les meilleures solutions techniques et
                    économiques. Travaux et études sur mesure pour des
                    réalisations évolutives répondant à vos besoins. ALPI
                    SOLUTIONS SARL réalise tous types de constructions
                    métalliques :
                </p>

                <div class="features-text">
                    <h4>
                        <i class="flaticon-tick"></i> Charpentes métalliques
                    </h4>
                    <h4><i class="flaticon-tick"></i> Extension de bâtiment</h4>
                    <h4>
                        <i class="flaticon-tick"></i> Fabrication et pose de
                        mezzanine en Acier «sur mesure»
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Passerelles et rampes
                        handicapées
                    </h4>
                    <h4><i class="flaticon-tick"></i> Rambardes de sécurité</h4>
                    <h4><i class="flaticon-tick"></i> Lignes de vies.</h4>
                    <h4><i class="flaticon-tick"></i> Plateformes</h4>
                    <h4><i class="flaticon-tick"></i> Bardages</h4>
                    <h4><i class="flaticon-tick"></i> Toitures</h4>
                </div>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/echaffaudage.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
