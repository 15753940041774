import { Component, OnInit } from "@angular/core";
import { of } from "rxjs";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
    currentYear: any;
    footerImages$ = of([1,2,3,4,5,6,7,8,9]);
    constructor() {}

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
    }
}