import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-publicite",
    templateUrl: "./service-publicite.component.html",
    styleUrls: ["./service-publicite.component.scss"],
})
export class ServicePubliciteComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
