<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Installation des lignes de vie.</h2>
                <p>
                    ALPI SOLUTIONS SARL , spécialisée dans le travail en
                    hauteur, réalise la pose de lignes de vie, de points
                    d’ancrages mécaniques et de filets de protection. Ces
                    travaux de mise en sécurité des accès, des personnes et des
                    structures sont réalisés pour les professionnels du
                    Bâtiment, des TRAVAUX PUBLIQUE et de l’Industrie. Nos
                    cordistes mettent en place tous types de lignes de vie
                    (ligne de vie toiture terrasse, ligne de vie horizontale et
                    ligne de vie verticale) quelle que soit leur longueur et sur
                    tous types de supports.
                </p>
                <p>
                    En complément à la pose de lignes de vie, l’installation de
                    filets de protection permet de protéger les façades, les
                    plafonds et les toitures contre les chutes de personnes ou
                    d’objets.
                </p>
            </div>

            <div
                class="services-details-image wow animate__animated animate__fadeInUp"
            >
                <img src="assets/img/services/ligne-de-vie.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
