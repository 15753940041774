<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Register</h2>

                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li>Register</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Signup Area -->
<div class="container">
    <div class="form-content">
        <div class="form-header">
            <h3>Create an Account</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
        </div>

        <form>
            <div class="form-group">
                <label>First name</label>
                <input type="text" class="form-control" id="first-name" placeholder="First name">
            </div>
            <div class="form-group">
                <label>Last name</label>
                <input type="text" class="form-control" id="last-name" placeholder="First name">
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="email" class="form-control" id="your-email" placeholder="Your email">
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" id="your-password" placeholder="Your password">
            </div>
            <button type="submit" class="default-btn">Signup</button>
        </form>

        <div class="form-footer">
            <p>Do you have an account? <a class="form-link" routerLink="/login">Login</a></p>
        </div>
    </div>
</div>
<!-- End Signup Area -->