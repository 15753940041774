import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-surface",
    templateUrl: "./service-surface.component.html",
    styleUrls: ["./service-surface.component.scss"],
})
export class ServiceSurfaceComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
