<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Maintenance Industrielle</h2>
                <p>
                    Notre mission est d’assurer la continuité de fonctionnement
                    de vos équipements, ou la remise en marche dans les
                    meilleurs délais. Tout en maintenant le niveau de sécurité
                    maximum. Avec un personnel formé et équipé.
                </p>
            </div>

            <div
                class="services-details-image wow animate__animated animate__fadeInUp"
            >
                <img src="assets/img/services/maintenance.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
