import { Component, OnInit } from '@angular/core';

@Component({
    selector: "app-service-espace-confine",
    templateUrl: "./service-espace-confine.component.html",
    styleUrls: ["./service-espace-confine.component.scss"],
})
export class ServiceEspaceConfineComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
