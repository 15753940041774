<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Nos Réalisations</h2>
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li>Nos Réalisations</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let data of datas$ | async">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <a routerLink="/blog-details"><img [src]="'assets/img/realisations/' + data.img" alt="image"></a>
                        </div>
                        <div class="post-content">
                            <!-- <div class="post-meta">
                                <ul>
                                    <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                    <li>June 24, 2019</li>
                                </ul>
                            </div> -->
                            <h3><a routerLink="#">{{data.title}}</a></h3>
                            <!-- <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                        </div>
                    </div>
                </div>
                    
            </ng-container>
        </div>
    </div>
</section>
<!-- End Blog Area -->
