<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>A propos de nous</h2>
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li>A propos de nous</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Qui sommes nous?</span>
                    <h2>ALPI SOLUTIONS SARL</h2>
                    <p>est une entreprise spécialisée dans les travaux en hauteur et d'accès difficiles et est constituée de professionnels diplômés d'état capables d’intervenir là où les moyens classiques ne peuvent plus accéder. Crée en 2016 par trois jeunes techniciens cordistes expérimentés. L'entreprise a su au fil des années se diversifier et s'approprier un savoir-faire de plus en plus pointu tant sur l'approche technique des missions qui lui sont confiées que sur l'aspect sécurité des moyens d'accès mis en place lors de nos interventions. Alliant techniques alpines et expérience dans divers corps de métiers, nos techniciens cordistes confirmés:</p>
                    <ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Toujours à la recherche de nouvelles techniques et Soucieux de les entretenir en permanence</span></li>
                        <li><span><i class="fas fa-check"></i> Peuvent intervenir sur tous les terrains</span></li>
                        <li><span><i class="fas fa-check"></i> A toutes les altitudes et en toutes saisons, apportant une alternative plus sûre</span></li>
                        <li><span><i class="fas fa-check"></i> Moins coûteuse et plus rapide à mettre œuvre que les grues, nacelles et échafaudages</span></li>
                        <!-- <li><span><i class="fas fa-check"></i> 24/7 Support</span></li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Pourquoi nous choisi?</h3>
                        <p>La formation, les qualifications et l'expérience de notre personnel, nous permettent de répondre
                            à la plupart des demandes et de réaliser des travaux en toute sécurité, avec rigueur,
                            rapidité et dans le respect de l’environnement.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Création. Résultats.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Comment nous travaillons?</h3>
                        <p>Chacune de nos interventions nécessite une préparation
                            spécifique avec la mise en place de modes opératoires détaillés et d'analyse
                            de risques propre à nos interventions et à l'environnement de travail qui nous entoure.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i>Bien penser</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Le niveau de nos services</h3>
                        <p>Il faut un personnel spécialement formé, connaissant et maitrisant parfaitement toutes
                            les méthodologies de mise en place des équipements. Parce que la sécurité est nôtres priorité.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> En attendre plus.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Notre Philosophie</h3>
                        <p>Harnais, cordes sont nos outils de progression.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Nous faisons confiance à la réalité.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->

<!-- Start Fun Facts Area -->
<!-- <section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Area -->

<!-- Start Why Choose Us Area -->
<!-- <section class="why-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <span class="sub-title">Why Choose Us</span>
                    <h2>The Key To Your Motivation And Success</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Core Development</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Define Your Choices</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/why-choose-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Why Choose Us Area -->

<!-- Start Solution Area -->
<!-- <section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">Watch Our Video</span>
                    <h2>Get Better Solution For Your Business</h2>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.
                    </p>
                    <a routerLink="/" class="default-btn">Contact Us <span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Solution Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Témoignages</span>
            <h2>Quelques commentaires élogieux de nos clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item">
            <p>“Merci pour votre professionalisme.
                j'ai été satisfait de votre travail.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client1.jpg" alt="image">

                <h3>Talla François</h3>
                <span>Client 1</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“Toiture entièrement refaite + zinguerie.
                Une prestation parfaite, rien à redire. Félicitations à l'entreprise et à ses employés,
                excellentes relations à l'écoute des remarques des clients.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client2.jpg" alt="image">

                <h3>Eboko Nka Solange</h3>
                <span>Client 2</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“Excellent travail pour l'instant.
                A recommander pour tous travaux concernant les travaux de charpente métalique.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client3.jpg" alt="image">

                <h3>Boyo Kevin</h3>
                <span>Client 3</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“Nous pensons que l'interaction avec la marque est la clé de la communication. De véritables innovations et une expérience client positive sont au cœur d'une communication réussie.”</p>
            <div class="client-info">
                <img src="assets/img/client-image/client5.jpg" alt="image">
                <h3>Alain Tchofo</h3>
                <span>Client 4</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Team Area -->
<!-- <section class="team-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Meet Our Awesome Team Member</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team1.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team2.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Lucy Eva</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team3.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section> -->
<!-- End Team Area -->

<!-- Start Subscribe Area -->
<!-- <section class="subscribe-area bg-F4F7FC">
    <div class="container-fluid p-0">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>

                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
</section> -->
<!-- End Subscribe Area -->

<!-- Start Partner Area -->
<section class="partner-area">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Nos Partenaires</span>
            <h2>Nous travaillons en partenariat avec des entreprises de toutes tailles, partout dans le monde.</h2>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item" *ngFor="let partner of partners$ | async">
                <a href="#">
                    <img [src]="'assets/img/partners/' + partner" alt="image">
                    <img [src]="'assets/img/partners/' + partner" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
</section>
<!-- End Partner Area -->