<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h2>Travaux sur pylônes</h2>
                <p>
                    Afin d’assurer la pérennité de vos pylônes et pour optimiser
                    la qualité de transmission et de réception des signaux,
                    optez pour des travaux de maintenance réguliers. Nous sommes
                    à votre service pour réaliser cette tâche. Grâce à notre
                    maîtrise technique, nous pouvons garantir la qualité et
                    l’efficacité de nos services de maintenance des pylônes
                    haubanés auto-stables et monopoles. D’ailleurs, nos
                    interventions répondent entièrement aux normes. Vous pouvez
                    faire appel à notre équipe pour réaliser des travaux divers
                    :
                </p>

                <div class="features-text">
                    <h4>
                        <i class="flaticon-tick"></i> Renforcement des pylônes
                        et de leurs fondations
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Inspections de la
                        structure et des équipements.
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Mise aux normes des
                        installations
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Mesure des câbles et des
                        antennes
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Remplacement des antennes,
                        du faisceau, des câbles
                    </h4>
                    <h4>
                        <i class="flaticon-tick"></i> Graissage des haubans,
                        maintenance de la structure, remplacement des
                        équipements. Etc…
                    </h4>
                </div>
                <p>
                    Quelles que soient les contraintes du chantier, notre équipe
                    saura intervenir avec brio.
                </p>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services/pylone.jpg" alt="image" />
            </div>
        </div>
    </div>
</section>
